import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import InjectionSchedule from './pages/InjectionSchedule';
import Embryo from './pages/Embryo';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/injection-schedule" element={<InjectionSchedule />} />
                    <Route path="/embryo" element={<Embryo />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;