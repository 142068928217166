import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel, Divider, List, ListItem, ListItemText } from '@mui/material';
import { debounce } from 'lodash';

const InjectionSchedule = ({ userId, handleClose }) => {
    const [injectionName, setInjectionName] = useState('');
    const [usagePurpose, setUsagePurpose] = useState('');
    const [unit, setUnit] = useState('');
    const [volume, setVolume] = useState('');
    const [injectionStartDate, setInjectionStartDate] = useState('');
    const [injectionEndDate, setInjectionEndDate] = useState('');
    const [injectionTime, setInjectionTime] = useState('');
    const [injectionCycle, setInjectionCycle] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const dictUsagePurpose = {
        gnrhAntagonist: "조기배란 억제",
        implantation: "착상 유도",
        hcg: "배란유도",
        fsh: "난포성숙"
    };

    const dictUnit = {
        iu: "IU",
        ml: "ml",
        mg: "mg",
        serving: "Serving",
        mcg: "mcg",
        gms: "gms",
        ug: "ug",
        usp: "usp",
        via: "via",
    };

    const fetchSuggestions = useCallback(async (query) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_HOST_URL}/injections/keyword/?search=${query}`);
            setSuggestions(response.data);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
    }, []);

    const debouncedFetchSuggestions = useCallback(
        debounce((query) => fetchSuggestions(query), 2000),
        [fetchSuggestions]
    );

    const handleInjectionNameChange = (e) => {
        const value = e.target.value;
        setInjectionName(value);
        if (value) {
            debouncedFetchSuggestions(value);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setInjectionName(suggestion.injectionName);
        setUsagePurpose(dictUsagePurpose[suggestion.usagePurpose] || suggestion.usagePurpose);
        setUnit(dictUnit[suggestion.unit] || suggestion.unit);
        setSuggestions([]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const injectionDateTime = `${injectionStartDate}T${injectionTime}`;
        const data = {
            userId,
            injection: {
                injectionName,
                usagePurpose: Object.keys(dictUsagePurpose).find(key => dictUsagePurpose[key] === usagePurpose) || usagePurpose,
                unit: Object.keys(dictUnit).find(key => dictUnit[key] === unit) || unit,
            },
            volume: parseInt(volume, 10),
            injectionStartDate,
            injectionEndDate,
            injectionTime: injectionDateTime,
            injectionCycle: parseInt(injectionCycle, 10),
        };

        console.log('Sending POST request with data:', data);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_HOST_URL}/injections/`, data);
            console.log('Response:', response.data);
            console.log('Data:', data);
            // 성공적으로 데이터를 전송한 후 추가적인 처리를 할 수 있습니다.
            handleClose(); // 폼 제출 후 모달 닫기
        } catch (error) {
            console.error('Error:', error);
            // 에러 처리 로직을 추가할 수 있습니다.
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
                주사제 추가
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <TextField
                label="주사제 이름"
                value={injectionName}
                onChange={handleInjectionNameChange}
                required
                fullWidth
                margin="normal"
                placeholder='주사제 이름을 입력하세요'
            />
            {suggestions.length > 0 && (
                <List>
                    {suggestions.map((suggestion, index) => (
                        <ListItem button key={index} onClick={() => handleSuggestionClick(suggestion)}>
                            <ListItemText primary={suggestion.injectionName} />
                        </ListItem>
                    ))}
                </List>
            )}
            <TextField
                label="사용 목적"
                value={usagePurpose}
                onChange={(e) => setUsagePurpose(e.target.value)}
                required
                fullWidth
                margin="normal"
                placeholder='미선택'
            />
            <TextField
                label="용량"
                value={volume}
                onChange={(e) => setVolume(e.target.value)}
                required
                fullWidth
                margin="normal"
                type="number"
                placeholder='최대 999까지 입력 가능'
            />
            <TextField
                label="단위"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                required
                fullWidth
                margin="normal"
                placeholder='미선택'
            />
            <TextField
                label="시작일"
                value={injectionStartDate}
                onChange={(e) => setInjectionStartDate(e.target.value)}
                required
                fullWidth
                margin="normal"
                type="date"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                label="종료일"
                value={injectionEndDate}
                onChange={(e) => setInjectionEndDate(e.target.value)}
                required
                fullWidth
                margin="normal"
                type="date"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                label="투여 시간"
                value={injectionTime}
                onChange={(e) => setInjectionTime(e.target.value)}
                required
                fullWidth
                margin="normal"
                type="time"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <FormControl fullWidth margin="normal">
                <InputLabel id="injection-cycle-label">투여 주기</InputLabel>
                <Select
                    labelId="injection-cycle-label"
                    value={injectionCycle}
                    onChange={(e) => setInjectionCycle(e.target.value)}
                    label="투여 주기"
                    required
                >
                    <MenuItem value={0}>매일</MenuItem>
                    <MenuItem value={1}>격일</MenuItem>
                    <MenuItem value={2}>3일</MenuItem>
                </Select>
            </FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button onClick={handleClose} color="primary" sx={{ mr: 2 }}>
                    취소
                </Button>
                <Button type="submit" variant="contained" color="primary">
                    저장
                </Button>
            </Box>
        </Box>
    );
};

export default InjectionSchedule;